import { useEffect, useState } from "react";

import { Modal, ModalBody } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormLogin from "./FormLogin";

const NavBar = () => {

    const [representante, setRepresentante] = useState(null);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {

        loadRepresentante();

    }, []);

    const loadRepresentante = () => {

        const representanteSalvo = localStorage.getItem("representante");

        if (representanteSalvo) {
            setRepresentante(JSON.parse(representanteSalvo).customer);
        }

    }

    const onSuccess = () => {

        loadRepresentante();

        setShowModal(false);
    }

    return (
        <div style={{ marginBottom: 100 }}>
            <div id="#navBar" className='p-3 text-center shadow bg-dark fixed-top'>
                <div className="row">
                    <div className="col-6 mt-1">
                        <div className='col-md-2 col-12'>
                            <Link to="/">
                                <img alt="Logo Pollaris" className='img-fluid' src="/assets/logoPollaris_h.svg">
                                </img>
                            </Link>
                        </div>
                    </div>

                    <div className="col-6">
                        {
                            representante ?
                                <button onClick={() => setShowModal(true)} title="Sou representante" className="float-end btn btn-outline-light">
                                    <i className="bi bi-person-badge"></i> {representante.fantasia}
                                </button>
                                :
                                <button onClick={() => setShowModal(true)} title="Sou representante" className="float-end btn btn-outline-light">
                                    <i className="bi bi-person-badge"></i>
                                </button>
                        }
                    </div>
                </div>
                <Modal show={showModal} dialogClassName={"modal-lg"}>
                    <div className="shadow bg-pollaris">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="mt-2"></div>
                                <div className="p-4 col-md-12 col-8 offset-md-0 offset-2">
                                    <img src="/assets/logoPollaris_h.svg" alt="Pollaris" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="p-3">
                                    <div className="p-5 bg-light shadow">
                                        <button onClick={() => setShowModal(false)} className="btn-close float-end"></button>
                                        <h5 className="mb-3 text-secondary">
                                            Representante
                                        </h5>
                                        <FormLogin onLoginSuccess={onSuccess} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div >
        </div>
    )
}

export default NavBar;