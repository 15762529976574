
import PollarisApi from '../services/PollarisApi';

import { useParams } from 'react-router-dom';


import React, { useEffect, useState } from "react";

import { Link } from 'react-router-dom';

const PageHome = (props) => {

  let loaded = false;

  document.title = "Pollaris Software - Tudo é mais fácil com o Pollaris menos perder o controle";

  const apiPollaris = new PollarisApi();

  const [produtos, setProdutos] = useState(null);

  const { code } = useParams();

  useEffect(() => {

    if (loaded) return;

    loaded = true;

    loadProdutos();

  }, []);

  const loadProdutos = (uf) => {

    apiPollaris.get(`produtos`).then(response => {

      console.log(response);

      setProdutos(response);
    }
    ).catch(error => {

      const message = error?.message || error.title;
    });

  }

  return produtos ? (
    <div className='container'>
      <div className='mt-4'>
        <div className='row'>
          {produtos.map(produto => (

            <div key={produto.id} className="col-md-4 mb-3">
              <div className={`bg-${produto.programa} shadow p-1 text-light`}>
                <div className='card-body p-3'>
                  <small>{produto.programa}</small>
                  <h5>{produto.nome}</h5>
                  <hr />
                  <Link to={`/formulario/${produto.link}`} className='btn btn-outline-light btn-lg col-12'>
                    <i className='bi bi-next'></i>
                    Contratar por <b>R${produto.mensalidade.toFixed(2)}</b>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div >
    </div >
  ) : null;

}

export default PageHome;