
import PollarisApi from '../services/PollarisApi';

import { useParams } from 'react-router-dom';

import React, { useEffect, useState } from "react";

import moment from 'moment';
import NavBar from '../components/NavBar';

const PageFinished = (props) => {

  let loaded = false;

  const apiPollaris = new PollarisApi();

  const [licenca, setLicenca] = useState(null);

  const { code } = useParams();

  useEffect(() => {

    if (loaded) return;

    loaded = true;

    loadLicenca();

  }, []);

  const loadLicenca = (uf) => {

    apiPollaris.get(`licenca/${code}/code`).then(response => {

      setLicenca(response);

      document.title = `Obrigado por contratar o ${response.programa} - ${response.descricao}`;

      document.body.classList.add(`bg-${response.produto.programa}`);

    }
    ).catch(error => {

      const message = error?.message || error.title;
    });

  }

  return licenca ? (
    <div>
      <div className="container">
        <div className="col-md-6 offset-md-3 mt-5">
          <div className='text-light mb-3 text-center'>
            <div style={{ fontSize: "7em" }}>
              <i className="bi bi-check-circle"></i>
            </div>
            <div style={{ fontSize: "3em" }}>
              Obrigado!
            </div>
          </div>
          <div className="card shadow text-center">
            <div className="card-body">

              <div className='offset-md-2 col-md-8'>
                Você contratou o <b>{licenca.programa} {licenca.descricao}</b> e ganhou <b>15 dias</b> para usar o sistema, começando a partir de {moment(licenca.contrato.dataHora).format("DD/MM/yyyy")}
              </div>

              <div className='mt-4'>
                Acesse a <a target="blank" href="https://cliente.pollaris.com.br/">Àrea do Cliente</a> para mais informações.
              </div>
              <div className='mt-4'>
                <a target="blank" href={`https://atualizacao.pollaris.com.br/Instalador${licenca.programa}.exe`} className='btn btn-lg btn-outline-dark'>Baixar programa</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  ) : null;

}

export default PageFinished;