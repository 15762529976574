import { useEffect, useState } from "react";
import PollarisApi from "../services/PollarisApi";
import ToastService from "../services/ToastService";

const FormLogin = ({ onLoginSuccess }) => {

    const apiPollaris = new PollarisApi(true);

    const toastService = new ToastService();

    const [invalidFormClass, setInvalidFormClass] = useState("");

    const [user, setUser] = useState("");

    const [password, setPassword] = useState("");

    const [manterConectado, setManterConectado] = useState(false);

    const logar = (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (user === "" || password === "") {
            setInvalidFormClass("was-validated");
            return
        }

        toastService.info('Aguarde..', "Validando informaçoes...")

        const base64Auth = btoa(`${user}:${password}`);

        apiPollaris
            .authenticate(base64Auth)
            .then((response) => {

                response.base64Auth = base64Auth;

                response.keep = manterConectado;

                localStorage.setItem("representante", JSON.stringify(response));

                toastService.success('Login efetuado com sucesso!', "Bem vindo!")

                onLoginSuccess();

            })
            .catch((error) => {

                toastService.error('Erro..', "Dados inválidos...")

                document.getElementById("user").focus();

                document.getElementById("user").select();

            });

    };

    return (<form onSubmit={logar} className={`needs-validation ${invalidFormClass}`} noValidate >
        <div className="mb-3">
            <div className="input-group input-group shadow border">
                <span className="input-group-text bg-light border-0" id="inputGroup-sizing-lg">
                    <i className="bi bi-person"></i>
                </span>
                <input autoFocus autoComplete="username" placeholder="e-mail" type="text" className="form-control bg-light border-0" required onChange={(e) => setUser(e.target.value)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" />
            </div>
        </div>
        <div className="mb-3">
            <div className="input-group input-group- shadow border">
                <span className="input-group-text bg-light border-0" id="inputGroup-sizing-lg">
                    <i className="bi bi-key"></i>
                </span>
                <input placeholder="senha" autoComplete="current-password" type="password" className="form-control bg-light border-0" required onChange={(e) => setPassword(e.target.value)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" />
            </div>
        </div>
        <div className="form-check form-switch mb-3">
            <input onChange={(e) => setManterConectado(e.target.checked)} className="form-check-input" type="checkbox" role="switch" id="checkBoxManterConectado" />
            <label className="form-check-label" htmlFor="checkBoxManterConectado">Manter conectado</label>
        </div>
        <div className="mt-2 mb-3">
            <button
                type="submit"
                className="btn btn-pollaris shadow col-12">
                <i className="bi bi-box-arrow-in-right"></i> {'Entrar'}
            </button>
        </div>
    </form>);

}

export default FormLogin;