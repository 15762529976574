
import { BrowserRouter, Routes, Route, NotFoundRoute, Navigate } from "react-router-dom"

import PageFormulario from "./PageFormulario";

import PageHome from "./PageHome";

import PageFinished from "./PageFinished";
import NavBar from "../components/NavBar";

function RoutesApp() {


    return (
        <BrowserRouter>
            <NavBar />
            <Routes>
                <Route exact path="/" element={<PageHome />} />
                <Route exact path="/formulario/:linkProduto" element={<PageFormulario />} />
                <Route exact path="/concluido/:code" element={<PageFinished />} />
            </Routes>
        </BrowserRouter >
    )
}

export default RoutesApp;