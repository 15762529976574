
import PollarisApi from '../services/PollarisApi';

import ToastService from '../services/ToastService';

import { useParams } from 'react-router-dom';

import React, { useEffect, useState } from "react";

import FormCliente from '../components/FormCliente';
import NavBar from '../components/NavBar';

const PageFormulario = (props) => {

  const apiPollaris = new PollarisApi();

  const toastService = new ToastService();

  let loaded = false;

  const [produto, setProduto] = useState(null);

  const [classColor, setClassColor] = useState(null);

  const { linkProduto } = useParams();

  useEffect(() => {

    if (loaded) return;

    loaded = true;

    loadProduto();

  }, []);

  const loadProduto = (uf) => {

    apiPollaris.get(`produtos?link=${linkProduto}`).then(response => {

      setProduto(response);

      document.title = "Pollaris Contratar - " + response.programa + ' ' + response.nome;

      setClassColor(`bg-${response.programa}`);

    }
    ).catch(error => {

      const message = error?.message || error.title;

      toastService.error("Erro ao carregar produto", message);
    }
    );

  }

  return (
    <div>
      {
        produto ?
          <div className='container'>
            <div className={`offset-md-3 col-md-6 mt-4 p-4 shadow ${classColor}`}>
              <div className='row text-light'>
                <div className='col-6'>
                  <small>{produto.programa}</small>
                  <h1 className='h3 mb-3 font-weight-normal'>{produto.nome}</h1>
                </div>
                <div className='col-6'>
                  <h1 className='float-end  mt-3 font-weight-normal'>R${produto.mensalidade.toFixed(2)}</h1>
                </div>
              </div>
              <div className='card shadow'>
                <div className='card-body'>
                  <FormCliente linkedProduto={produto} />
                </div>
              </div>
            </div>
          </div>
          : false
      }
    </div>
  )

}

export default PageFormulario;